<template>
  <div class="left-menu">
    <div :class="[activeMenu === 'base-set' ? 'active' : '', 'menu-item']" @click="toPage('base-set')">
      <el-icon class="icon el-icon-setting"></el-icon>
      <span>基础设置</span>
    </div>
    <div :class="[activeMenu === 'vision' ? 'active' : '', 'menu-item']" @click="toPage('vision')">
      <el-icon class="icon el-icon-view"></el-icon>
      <span>视觉设置</span>
    </div>
    <div :class="[activeMenu === 'hots' ? 'active' : '', 'menu-item']" @click="toPage('hots')">
      <el-icon class="icon el-icon-position"></el-icon>
      <span>热点管理</span>
    </div>
    <div :class="[activeMenu === 'sand' ? 'active' : '', 'menu-item']" @click="toPage('sand')">
      <el-icon class="icon el-icon-guide"></el-icon>
      <span>沙盘设置</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LeftMenu',
  data () {
    return {
      activeMenu: ''
    }
  },
  created () {
    this.componInit()
  },
  methods: {
    componInit () {
      const index = this.$route.fullPath.lastIndexOf('/')
      this.activeMenu = this.$route.fullPath.substring(index + 1, this.$route.fullPath.length)
    },
    toPage (url) {
      if (url !== this.activeMenu) {
        this.$router.push('/panorama/' + this.$route.params.project_id + '/' + url)
        this.activeMenu = url
      }
    }
  },
  watch: {
    activeMenu (newMenu, oldMenu) {
      this.activeMenu = newMenu
    }
  }
}
</script>

<style scoped>
  .left-menu{
    width: 100%;
    height: 100%;
    background-color: #1c2438;
  }
  .menu-item{
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #80848f;
    border-bottom: 1px dashed #0a0f15;
    cursor: pointer;
  }
  .menu-item .icon{
    font-size: 24px;
  }
  .menu-item span{
    font-size: 12px;
    margin-top: 4px;
  }
  .left-menu .active{
    background-color: #E6A23C;
    color: #FFFFFF;
    box-shadow: rgba(230, 162, 60, 0.25) 0px 1px 20px 3px, rgba(230, 162, 60, 0.8) 0px 5px 11px 0px;
    border-bottom: none;
  }
</style>
