<template>
  <div class="top-head">
    <div class="left-menu" @click="closeWindow">
      <el-icon class="el-icon-close"></el-icon>
    </div>
    <div class="title">
      {{$store.state.panoramaInfo.title}}
    </div>
    <div class="right-menu">
      <div class="item save" @click="save">
        <el-icon class="el-icon-receiving"></el-icon>
        <span>保 存</span>
      </div>
      <div class="item preview">
        <el-icon class="el-icon-view"></el-icon>
        <span>预 览</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TopHead',
  methods: {
    async save () {
      const { data: res } = await this.$http.post('/panorama-save-project', this.$store.state.panoramaInfo)
      if (res.status === 200) {
        this.$message.success(res.msg)
        await this.getProjectInfo()
      } else {
        this.$message.error(res.msg)
      }
    },
    async getProjectInfo () {
      const { data: res } = await this.$http.get('/panorama-project', { params: { mid: this.$route.params.project_id } })
      if (res.status === 200) {
        this.$store.commit('updatePanoramaAll', res.data.projectInfo)
        this.$store.commit('updateSceneList', res.data.sceneList)
      } else {
        this.$message.error(res.msg)
      }
    },
    // 关闭页面
    closeWindow () {
      this.$confirm('此操作将关闭本页面，并不会保存相关项, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        window.opener = null
        window.open('', '_self')
        window.close()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    }
  }
}
</script>

<style scoped>
  .top-head{
    width: 100%;
    height: 60px;
    background-color: #1c2438;
    text-align: center;
    position: relative;
  }
  .left-menu {
    position: absolute;
    left: 20px;
    top: 14px;
    width: 42px;
    height: 32px;
    background-color: #F56C6C;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    font-weight: bold;
    border-radius: 4px;
    cursor: pointer;
  }
  .left-menu:hover{
    box-shadow: rgba(245, 108, 108, 0.25) 0px 1px 20px 3px, rgba(245, 108, 108, 0.8) 0px 5px 11px 0px;
  }
  .title{
    color: #FFFFFF;
    font-size: 14px;
    line-height: 60px;
  }
  .right-menu{
    position: absolute;
    right: 20px;
    top: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .right-menu .item{
    width: 80px;
    height: 32px;
    border-radius: 4px;
    cursor: pointer;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    line-height: 32px;
  }
  .right-menu .item span{
    margin-left: 6px;
  }
  .right-menu .save{
    background-color: #19be6b;
  }
  .right-menu .save:hover{
    box-shadow: rgba(25, 190, 107, 0.25) 0px 1px 20px 3px, rgba(25, 190, 107, 0.8) 0px 5px 11px 0px;
  }
  .right-menu .preview{
    background-color: #E6A23C;
    margin-left: 20px;
  }
  .right-menu .preview:hover{
    box-shadow: rgba(230, 162, 60, 0.25) 0px 1px 20px 3px, rgba(230, 162, 60, 0.8) 0px 5px 11px 0px;
  }
</style>
