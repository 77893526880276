<template>
  <div class="home-page">
    <div class="heade">
      <top-head></top-head>
    </div>
    <div class="content">
      <div class="left-menu">
        <left-menu></left-menu>
      </div>
      <div class="right-content">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import TopHead from '@/components/apps/panorama/projectItem/common/TopHead'
import LeftMenu from '@/components/apps/panorama/projectItem/common/LeftMenu'
import 'default-passive-events'
export default {
  name: 'Index',
  components: {
    LeftMenu,
    TopHead
  },
  created () {
    this.getProjectInfo()
  },
  methods: {
    async getProjectInfo () {
      const { data: res } = await this.$http.get('/panorama-project', { params: { mid: this.$route.params.project_id } })
      if (res.status === 200) {
        this.$store.commit('updatePanoramaAll', res.data.projectInfo)
        this.$store.commit('updateSceneList', res.data.sceneList)
        this.$store.commit('updateCameraList', res.data.cameraList)
        this.$store.commit('updateHotsList', res.data.hotsList)
      } else {
        this.$message.error(res.msg)
      }
    }
  }
}
</script>

<style scoped>
.home-page{
  width: 100%;
  height: 100vh;
}
.content{
  width: 100%;
  height: calc(100vh - 60px);
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.left-menu{
  width: 80px;
  height: 100%;
}
.right-content{
  width: calc(100% - 80px);
  height: calc(100vh - 60px);
  overflow-y: auto;
  background-color: #495060;
  position: relative;
}
</style>
